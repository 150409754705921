import React, { useRef, useEffect, useCallback } from 'react'
import { FaTwitter, FaFacebookF, FaInstagram } from 'react-icons/fa'
import PropTypes from 'prop-types'
import Logo from '~/components/Logo'
import Marker from '~/images/marker.png'
import { mapStyle } from './map-style'
import './footer.styles.css'

const token = process.env.GATSBY_MAPS_TOKEN

const mapLink =
  'https://www.google.com/maps/dir//Cervezas+Llanura+SL,+Calle+Juan+Carrasco,+1,+02630+La+Roda,+Albacete/@39.2012619,-2.1634892,19.29z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd663614bc09d921:0x86cdd456a50bad44!2m2!1d-2.1637504!2d39.2010247!3e0'

const Footer = ({ siteTitle }) => {
  const ref = useRef(null)
  const onMapsLoadCallback = useCallback(() => {
    var map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 39.200983, lng: -2.163675 },
      zoom: 15,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: mapStyle,
    })
    var image = {
      url: Marker,
      scaledSize: new window.google.maps.Size(51, 63),
    }
    var marker = new window.google.maps.Marker({
      position: { lat: 39.200983, lng: -2.163675 },
      map: map,
      animation: window.google.maps.Animation.DROP,
      icon: image,
    })
    marker.setOpacity(0.8)
    // marker.addListener('click', function() {
    //   map.setZoom(8)
    //   map.setCenter(marker.getPosition())
    // })
  }, [])
  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.async = true
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${token}&libraries=places`
    scriptTag.addEventListener('load', onMapsLoadCallback)
    ref.current.appendChild(scriptTag)
  }, [onMapsLoadCallback])
  return (
    <footer ref={ref} className="footer" style={{ color: '#e4c06a' }}>
      <div className="footer_column contact-container">
        <div className="contact_copyright contact_text">
          <Logo color="#ffffffcc" />
          <p>
            Has cambiado tu forma de pensar, has cambiado tu estilo de vida, has cambiado tus
            hábitos de consumo. Ya es hora de que cambies de cerveza. Greyhound Brewers. Cambio,
            pasión y mucho lúpulo. #WeAreChanging
          </p>
        </div>
        <div className="contact_copyright">
          <div className="contact_links">
            <a
              className="contact_link"
              href="https://twitter.com/CervezasLlanura"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              className="contact_link"
              href="https://es-es.facebook.com/pg/CervezasLlanura/posts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              className="contact_link"
              href="https://www.instagram.com/cervezasllanura/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <p className="contact_text">
            {siteTitle} © {new Date().getFullYear()}
            <a href="/terminos-y-condiciones.pdf" target="_blank" rel="noopener noreferrer">
              Términos y condiciones
            </a>
            <a href="/formulario-desistimiento.pdf" target="_blank" rel="noopener noreferrer">
              Formulario desestimiento datos personales
            </a>
          </p>
        </div>
      </div>
      <div className="footer_column map_container">
        <div className="placecard">
          <div className="placecard__container">
            <div className="placecard__left">
              <p className="placecard__business-name">Greyhound brewers</p>
              <p className="placecard__info">administracion@llanura.es</p>
              <p className="placecard__info">
                Calle Juan Carrasco 1, 02630 <br />
                La Roda (Albacete)
              </p>
              <a
                className="placecard__view-large"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/Cervezas+Llanura+SL/@39.2010247,-2.1659391,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x86cdd456a50bad44!8m2!3d39.2010247!4d-2.1637504!9m1!1b1?hl=es"
              >
                Reseñas
              </a>
              <a
                className="placecard__view-large"
                rel="noopener noreferrer"
                target="_blank"
                href="https://goo.gl/maps/uZnLf2hh1qBbNvZC6"
              >
                Ver en google maps
              </a>
            </div>
            <div className="placecard__right">
              <a
                className="placecard__direction-link"
                target="_blank"
                rel="noopener noreferrer"
                href={mapLink}
              >
                <div className="placecard__direction-icon"></div>
                Cómo llegar
              </a>
            </div>
          </div>
        </div>
        <div id="map" className="map"></div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: 'Greyhound',
}

export default Footer
