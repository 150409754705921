import React from 'react'
import PropTypes from 'prop-types'
import LogoIcon from '~/images/logo.svg'

const Logo = ({ color }) => <LogoIcon style={{ fill: color }} />

Logo.propTypes = {
  color: PropTypes.string,
}

Logo.defaultProps = {
  color: '#2B2A29',
}

export default Logo
